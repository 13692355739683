<template>
  <button
    :disabled="disabled"
    :style="styling"
    class="filter-button"
    v-class-mod:filter-button="[`size-${size}`, { isActive }]"
  >
    <icon-wrapper v-bind="icon" size="medium" />
    <span v-if="title && isActive" class="filter-button__title">{{ title }}</span>

    <tooltip v-bind="tooltip">
      <span
        :tabindex="disabled ? -1 : 0"
        class="filter-button__btn-click"
        @click="clickHandler"
        @keydown.enter="clickHandler"
      />
    </tooltip>
    <icon-toggle-button
      v-if="resetBtn && isActive"
      v-bind="resetBtn"
      size="medium"
      button-size="medium"
      class="filter-button__icon-btn"
    />
  </button>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { ButtonSize, ColorName } from "@horizon56/styles/types";

import IconToggleButton, { IconToggleButtonProps } from "@/components/buttons/icon-toggle-button.vue";
import IconWrapper, { IconWrapperProps } from "@/components/icons/icon-wrapper.vue";
import Tooltip, { TooltipProps } from "@/components/tooltips/tool-tip.vue";

const props = withDefaults(
  defineProps<{
    title?: string;
    size?: ButtonSize;
    isActive?: boolean;
    disabled?: boolean;
    tooltip?: TooltipProps;
    action?: (event: MouseEvent | KeyboardEvent) => void;
    icon: IconWrapperProps;
    resetBtn?: IconToggleButtonProps;
    stylingOptions?: {
      background?: ColorName;
      hoverBackground?: ColorName;
      activeBackground?: ColorName;
      activeHoverBackground?: ColorName;
      borderColor?: ColorName;
    };
  }>(),
  {
    size: "large",
  },
);

const styling = computed(
  () => `
  --background: var(--${props?.stylingOptions?.background || "transparent"});
--hoverBackground: var(--${props?.stylingOptions?.hoverBackground || "black-20"});
--activeBackground: var(--${props?.stylingOptions?.activeBackground || "black-20"});
--borderColor: var(--${props?.stylingOptions?.borderColor || "black-50"});
--activeHoverBackground: var(--${props?.stylingOptions?.activeHoverBackground || "black-28"});
`,
);

const clickHandler = (event: MouseEvent | KeyboardEvent) => {
  if (!props.disabled && !!props?.action) {
    props.action?.(event);
  }
};
</script>

<style lang="scss" scoped>
.filter-button {
  $block: &;
  display: inline-flex;
  flex-shrink: 0;
  color: var(--color, var(--black-90));
  overflow: hidden;
  align-items: center;
  @include focus-outline;
  position: relative;
  padding-right: 0;
  cursor: pointer;
  background: var(--background, transparent);
  &:hover {
    background: var(--hoverBackground);
  }
  &__icon-btn,
  &__title {
    margin-left: 8px;
  }
  &__btn-click {
    position: absolute;
    @include position-corner;
    border: 2px solid transparent;
  }
  @each $size in $buttonSizes {
    &--size-#{$size} {
      min-width: var(--app-button-height-#{$size});
      height: var(--app-button-height-#{$size});
      padding-left: calc((var(--app-button-height-#{$size}) - var(--app-icon-size-medium)) / 2);
      border-radius: var(--app-button-height-#{$size});
      #{$block}__btn-click {
        border-radius: var(--app-button-height-#{$size});
      }
    }
  }
  &--size-large {
    padding-right: 4px;
  }
  &--isActive &__btn-click {
    border-color: var(--borderColor);
  }
  &--isActive {
    background: var(--activeBackground);
    &:hover {
      background: var(--activeHoverBackground);
    }
  }
  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}
</style>
